export type TenantName = "default" | "docfleck" | "baskast";

export const DEFAULT_TENANT_NAME = "default" as TenantName;
export const BAS_KAST_TENANT_NAME = "baskast" as TenantName;
export const DOC_FLECK_TENANT_NAME = "docfleck" as TenantName;

export const TENANT_NAMES: TenantName[] = [
  DEFAULT_TENANT_NAME,
  BAS_KAST_TENANT_NAME,
  DOC_FLECK_TENANT_NAME,
];

export const TENANT_DOMAINS: Record<TenantName, string> = {
  default: "hellohealth.de",
  docfleck: "docfleck.hellohealth.de",
  baskast: "baskast.hellohealth.de",
};
