"use client";

import React, { useEffect, useReducer } from "react";

import { CartContext } from "../contexts";
import { saveLSCart } from "../localstorage/cart";
import {
  Cart,
  CartItem,
  CartLineItemWithProduct,
  addToCart,
  getCart,
  updateCart,
} from "./cart";
import { CartReducer } from "./cartReducer";

interface Props {
  children: React.ReactNode;
}

export const CartProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(CartReducer, {
    items: {},
    updateStatus: "LOCAL",
  } as unknown as Cart);

  // Load saved cart items from local storage when component mounts
  useEffect(() => {
    const loadFromLocalstorage = async () => {
      const savedCartItems = await getCart();
      if (savedCartItems) {
        dispatch({
          type: "LOAD_ITEMS",
          payload: savedCartItems,
          updateType: "SHOPIFY",
        });
      }
    };
    loadFromLocalstorage();
  }, []);

  const deleteCartItem = (item: CartLineItemWithProduct) => {
    dispatch({ type: "DELETE_ITEM", payload: item, updateType: "SHOPIFY" });
  };

  const setCartItemAmount = (
    item: CartLineItemWithProduct | CartItem,
    amount: number
  ) => {
    const itemCopy = { ...item };
    itemCopy.amount = amount;

    const cartLineItem = itemCopy as CartLineItemWithProduct;

    if (
      state.items[itemCopy.variantId] &&
      state.items[itemCopy.variantId].amount > 0 &&
      state.items[itemCopy.variantId].id
    ) {
      //cart & item exists -> update item amount
      dispatch({
        type: "SET_ITEM",
        payload: { setLoad: true, item: { ...cartLineItem } },
        updateType: "LOCAL",
      });

      updateCart(state.cartId, {
        ...cartLineItem,
        cost: { ...cartLineItem.cost },
      }).then((cart) => {
        const items = { ...cart.items };

        //if we set an items amount to 0 it will have disappeared in the returned cart here so we need to add it again
        // Object.keys(state.items).forEach((itemKey) => {
        //   const aitem = state.items[itemKey];
        //   if (aitem.amount == 0) {
        //     items[aitem.variantId] = aitem;
        //   }
        // });
        // if (amount == 0) {
        //   items[cartLineItem.variantId] = cartLineItem;
        // }

        dispatch({
          type: "SET_ITEM_COST",
          payload: items,
          updateType: "SHOPIFY",
        });
      });
    } else if (state.cartId) {
      const cartItem = itemCopy as CartItem;
      //cart exists but item doesnt -> add item

      addToCart(state.cartId, [{ ...cartItem }]).then((cart) => {
        const items = { ...cart.items };

        //if we set an items amount to 0 it will have disappeared in the returned cart here so we need to add it again
        // Object.keys(state.items).forEach((itemKey) => {
        //   const aitem = state.items[itemKey];
        //   if (aitem.amount == 0) {
        //     items[aitem.variantId] = aitem;
        //   }
        // });

        dispatch({
          type: "SET_ITEM_COST",
          payload: items,
          updateType: "SHOPIFY",
        });

        dispatch({
          type: "SET_ITEM",
          payload: { setLoad: false, item: cart.items[itemCopy.variantId] },
          updateType: "LOCAL",
        });
      });
    } else {
      //cart doesn't exist
      addToCart(null, [{ ...itemCopy }]).then((cart) => {
        dispatch({
          type: "LOAD_ITEMS",
          payload: cart,
          updateType: "SHOPIFY",
        });
        saveLSCart(cart);
      });
    }
  };

  return (
    <CartContext.Provider
      value={{
        cart: state,
        setCartItemAmount,
        deleteCartItem,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
