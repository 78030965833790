import {
  ICourseUpsellProductFields,
  IProductFields,
} from "../../@types/generated/contentful";
import { ShopifyProductWithVariant } from "../../@types/shopifyProductWithVariant";
import { CartItem, CartLineItemWithProduct } from "../cart/cart";

/**
 * All ICourseUpsellProduct-Objects are decorated with data from shopify for the product itself in `modifyResponse.js`
 */
export interface ICourseUpsellProductFieldsModified
  extends ICourseUpsellProductFields {
  shopifyProduct: ShopifyProductWithVariant;
}
/**
 * All IProductFields-Objects are decorated with data from shopify for the product itself and a possible extraProduct in `modifyResponse.js`
 */
export interface IProductFieldsModified extends IProductFields {
  shopifyProduct: ShopifyProductWithVariant;
  extraShopifyProduct?: ShopifyProductWithVariant;
  extraShopifyProduct2?: ShopifyProductWithVariant;
}

/**
 * Produces an array of variantIds, given a product: IProductFieldsModified
 *
 * @param product: IProductFieldsModified
 * @returns Array of variantIds to checkout
 */
export const getVariantIdsToCheckout = (product: IProductFieldsModified) => {
  const checkoutProducts = [product.shopifyProduct.variantId];
  if (product.extraShopifyProduct) {
    checkoutProducts.push(product.extraShopifyProduct?.variantId);
  }
  if (product.extraShopifyProduct2) {
    checkoutProducts.push(product.extraShopifyProduct2?.variantId);
  }
  return checkoutProducts;
};

export const createCheckoutApiURL = (
  variantIds: string[],
  discount?: string | undefined
) => {
  return `/api/checkout?variantIds=${encodeURIComponent(
    JSON.stringify(variantIds)
  )}${discount ? `&discount=${discount}` : ""}`;
};

export const createCartApiURL = (cartItems: CartItem[]) => {
  return `/api/createCart?cartItems=${encodeURIComponent(
    JSON.stringify(cartItems)
  )}`;
};
export const getCartApiURL = (cartId: string) => {
  return `/api/getCart?cartId=${encodeURIComponent(cartId)}`;
};
export const getUpdateCartApiURL = (
  cartItem: CartLineItemWithProduct,
  cartId: string
) => {
  const transformedItem = {
    id: cartItem.id,
    variantId: cartItem.variantId,
    amount: cartItem.amount,
  };
  return `/api/updateCart?cartId=${encodeURIComponent(
    cartId
  )}&cartItem=${encodeURIComponent(JSON.stringify(transformedItem))}`;
};
export const addItemToCartApiURL = (cartItems: CartItem[], cartId: string) => {
  return `/api/addToCart?cartId=${encodeURIComponent(
    cartId
  )}&cartItems=${encodeURIComponent(JSON.stringify(cartItems))}`;
};
