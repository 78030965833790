import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";

import type { AppProps } from "next/app";

import Tracking from "../components/Tracking";
import "../styles/custom.less";
import globalTheme, { getTenantTheme } from "../styles/globalTheme";
import { CartProvider } from "../utils/cart/cartProvider";
import { TenantContext } from "../utils/contexts";
import createEmotionCache from "../utils/createEmotionCache";
import applyThemeToStylesheet from "../utils/page/applyThemeToStylesheet";

const clientSideEmotionCache = createEmotionCache();

interface AppPropsWithEmotion extends AppProps {
  emotionCache?: any;
}

export const NEXT_PUBLIC_TAG_MANAGER =
  process.env.NEXT_PUBLIC_TAG_MANAGER_ID || "";

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: AppPropsWithEmotion) {
  // const router = useRouter();

  // useEffect(() => {
  //   const setOptimizeActivationCookie = async () => {
  //     if (window.dataLayer) {
  //       await window.dataLayer.push({ event: "optimize.activate" });
  //     }
  //   };
  //   setOptimizeActivationCookie();
  // }, []);

  // useEffect(() => {
  //   router.events.on("routeChangeComplete", pageview);
  //   return () => {
  //     router.events.off("routeChangeComplete", pageview);
  //   };
  // }, [router.events]);

  const theme = getTenantTheme(pageProps.theme) || globalTheme;
  applyThemeToStylesheet(theme);
  return (
    <>
      {/* <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${NEXT_PUBLIC_TAG_MANAGER}');
          `,
        }}
      /> */}
      <TenantContext.Provider value={{ tenant: pageProps.theme }}>
        <CartProvider>
          <CacheProvider value={emotionCache}>
            <ThemeProvider theme={theme}>
              {/* <ThemeRegistry tenant={pageProps.theme}> */}
              <Tracking tagManager={NEXT_PUBLIC_TAG_MANAGER}>
                <Component {...pageProps} />{" "}
              </Tracking>
              {/* </ThemeRegistry> */}
            </ThemeProvider>
          </CacheProvider>
        </CartProvider>
      </TenantContext.Provider>
    </>
  );
}

export default MyApp;
