import { Cart } from "../cart/cart";

const CART_LOCALSTORAGE_PATH = "cart";

/**
 * Helper functions to add & remove from, and retrieve the cart from localstorage.
 */
export const getLSCart = () => {
  try {
    const lsCart = window.localStorage.getItem(CART_LOCALSTORAGE_PATH);
    return (lsCart ? JSON.parse(lsCart) : null) as Cart;
  } catch (e) {
    console.log("Couldnt load localstorage Cart - " + e);
  }
};
export const deleteLSCart = () => {
  try {
    window.localStorage.removeItem(CART_LOCALSTORAGE_PATH);
  } catch (e) {
    console.log("Couldnt delete localstorage Cart - " + e);
  }
};

export const saveLSCart = async (cart: Cart) => {
  saveCart(cart);
};

const saveCart = (cart: Cart) => {
  try {
    window.localStorage.setItem(CART_LOCALSTORAGE_PATH, JSON.stringify(cart));
  } catch (e) {
    console.log("Could not save cart in localstorage - " + e);
  }
};
