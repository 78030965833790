"use client";

import { useRouter } from "next/navigation";
import Script from "next/script";

import { useEffect } from "react";

export default function Tracking({
  children,
  tagManager,
}: {
  children: React.ReactNode;
  tagManager: string;
}) {
  const pageview = (url: string) => {
    window.dataLayer.push({
      event: "pageview",
      page: url,
    });
  };
  const router = useRouter();

  useEffect(() => {
    const setOptimizeActivationCookie = async () => {
      if (window.dataLayer) {
        await window.dataLayer.push({ event: "optimize.activate" });
      }
    };
    setOptimizeActivationCookie();
  }, []);

  // TODO: FIXME
  //   useEffect(() => {
  //     router.events.on("routeChangeComplete", pageview);
  //     return () => {
  //       router.events.off("routeChangeComplete", pageview);
  //     };
  //   }, [router.events]);

  return (
    <>
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${tagManager}');
          `,
        }}
      />
      {children}
    </>
  );
}
