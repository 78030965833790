"use client";

import { createContext } from "react";

import { ShopifyProductWithVariant } from "../../@types/shopifyProductWithVariant";
import { DEFAULT_TENANT_NAME } from "../../utils/tenant-names";
import { Cart, CartItem, CartLineItemWithProduct } from "../cart/cart";

interface TenantContextType {
  tenant: string;
}
export const TenantContext = createContext<TenantContextType>({
  tenant: DEFAULT_TENANT_NAME,
});

export const ContentSectionThemeContext = createContext<{
  theme: "light" | "dark";
  textColor?: string;
  isBackgroundSet?: boolean;
}>({ theme: "light" });

export const ShopifyProductContext = createContext<{
  value: ShopifyProductWithVariant | null;
}>({ value: null });

export type CartContextType = {
  cart: Cart;
  setCartItemAmount: (
    item: CartLineItemWithProduct | CartItem,
    amount: number
  ) => void;
  deleteCartItem: (item: CartLineItemWithProduct) => void;
};

export const CartContext = createContext<CartContextType>({
  cart: null as unknown as Cart,
  setCartItemAmount: () => {},
  deleteCartItem: () => {},
});
