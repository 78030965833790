import { tableSortLabelClasses } from "@mui/material";

import { ShopifyProductWithVariant } from "../../@types/shopifyProductWithVariant";
import { deleteLSCart, getLSCart } from "../localstorage/cart";
import {
  addItemToCartApiURL,
  createCartApiURL,
  getCartApiURL,
  getUpdateCartApiURL,
} from "../shopify/createCart";

export interface ProductCost {
  amountPerQuantity: number;
  compareAtAmountPerQuantity: number | undefined;
  subtotalAmount: number;
  totalAmount: number;
}

export interface CartItem {
  variantId: string;
  amount: number;
}

export interface CartLineItem {
  amount: number;
  id: string;
  variantId: string;
  cost: ProductCost;
  discountAllocations: any;
}
export interface CartLineItemWithProduct extends CartLineItem {
  product: ShopifyProductWithVariant;
}

export interface CartItemMap {
  [variantId: string]: CartLineItemWithProduct;
}

export interface Cart {
  isUpdatingPrices: boolean;
  cartId: string;
  items: CartItemMap;
  checkoutURL: string;
  discountCodes: any; //DiscountCode[],
  discountAllocations: any; //DiscountAllocation[]
  updateStatus: "LOCAL" | "SHOPIFY";
}

export const getCartSumTotal = (cart: Cart): number => {
  if (!cart || Object.keys(cart.items).length == 0) return 0;
  return Object.keys(cart.items).reduce((prev: number, curr: string) => {
    if (cart.items[curr].amount > 0) {
      if (cart.items[curr].id != "") {
        const cartLineItem = cart.items[curr] as CartLineItemWithProduct;
        prev += cartLineItem.cost.totalAmount;
      }
    }
    return prev;
  }, 0);
};
export const getCartSumTotalWithoutDiscounts = (cart: Cart): number => {
  if (!cart || Object.keys(cart.items).length == 0) return 0;
  return Object.keys(cart.items).reduce((prev: number, curr: string) => {
    if (cart.items[curr].amount > 0) {
      if (cart.items[curr].id != "") {
        const cartLineItem = cart.items[curr] as CartLineItemWithProduct;
        prev += cartLineItem.cost.subtotalAmount;
      }
    }
    return prev;
  }, 0);
};

export const getPriceComponents = (price: number) => {
  let mwst = Math.round(100 * price * 0.19) / 100;
  let basePrice = price - mwst;
  return { mwst, basePrice };
};

export const getCartItemAmount = (cart: Cart): number => {
  if (!cart) return 0;

  return cart.items
    ? Object.keys(cart.items).reduce(
        (prev: number, curr: string) => prev + cart.items[curr].amount,
        0
      )
    : 0;
};

export const isCartEmpty = (cart: Cart) => {
  return cart && getCartItemAmount(cart) === 0;
};

export const getCartItems = (cart: Cart): ShopifyProductWithVariant[] => {
  return Object.keys(cart.items).flatMap((variantId) =>
    Array(cart.items[variantId].amount).fill(cart.items[variantId].product)
  );
};

/**
 * Helper functions to update & retrieve the cart.
 */
export const getCart = async () => {
  const lsCart = getLSCart();
  if (lsCart) {
    const cartId = lsCart.cartId;
    const response = await fetch(getCartApiURL(cartId));
    const parsed = await response.json();

    const cart = parsed.cart;
    if (cart) {
      const newCart = mapGQLResponseToCart(cart, "SHOPIFY");
      return newCart;
    }
  }
  deleteLSCart();
  return null;
};

export const updateCart = async (
  cartId: string,
  cartLineItem: CartLineItemWithProduct
) => {
  const response = await fetch(getUpdateCartApiURL(cartLineItem, cartId));

  const parsed = await response.json();

  const cart = parsed.cart;
  const newCart = mapGQLResponseToCart(cart, "SHOPIFY");
  newCart.isUpdatingPrices = true;
  return newCart;
};

export const addToCart = async (
  cartId: string | null,
  cartItems: CartItem[]
) => {
  let response;
  if (cartId) {
    response = await fetch(addItemToCartApiURL(cartItems, cartId));
  } else {
    response = await fetch(createCartApiURL(cartItems));
  }
  let parsedResponse = await response.json();
  const newCart = mapGQLResponseToCart(parsedResponse.cart, "SHOPIFY");
  newCart.isUpdatingPrices = true;
  return newCart;
};

const mapGQLResponseToCart = (cart: any, status: "LOCAL" | "SHOPIFY") => {
  const newCart = {
    cartId: cart.id,
    updateStatus: status,
    items: {},
    discountAllocations: cart.discountAllocations,
    discountCodes: cart.discountCodes,
    checkoutURL: cart.checkoutUrl,
  } as Cart;
  cart.lines.edges.forEach((edge: any) => {
    const { cost, merchandise, quantity } = edge.node;
    const { id, description, media, title, priceRange } = merchandise.product;
    const { minVariantPrice, maxVariantPrice } = priceRange;
    const {
      totalAmount,
      subtotalAmount,
      amountPerQuantity,
      compareAtAmountPerQuantity,
    } = cost;
    const mediaImage =
      media.edges && media.edges.length ? media.edges[0].node : null;
    const product = {
      id: id,
      title: title,
      description: description,
      variantId: merchandise.id,
      media: mediaImage
        ? { alt: mediaImage.alt, previewUrl: mediaImage.previewImage.url }
        : null,
      price: merchandise.price,
      minPrice: minVariantPrice,
      maxPrice: maxVariantPrice,
      variantTitle: merchandise.title,
    } as ShopifyProductWithVariant;

    newCart.items[merchandise.id] = {
      amount: quantity,
      variantId: product.variantId,
      product,
      id: edge.node.id,
      discountAllocations: edge.node.discountAllocations,
      cost: {
        totalAmount: parseFloat(totalAmount.amount),
        subtotalAmount: parseFloat(subtotalAmount.amount),
        amountPerQuantity: parseFloat(amountPerQuantity.amount),
        compareAtAmountPerQuantity: compareAtAmountPerQuantity
          ? parseFloat(compareAtAmountPerQuantity.amount)
          : undefined,
      },
    };
  });

  return newCart;
};
