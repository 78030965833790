"use client";

import { createTheme } from "@mui/material/styles";

import localFont from "next/font/local";
import Link from "next/link";

import { forwardRef } from "react";

const HafferSQ = localFont({
  display: "swap",
  src: [
    {
      path: "../public/fonts/haffer/HafferSQ-Regular.woff2",
      weight: "400",
      style: "normal",
    },
    // {
    //   path: "../public/fonts/haffer/HafferSQ-SemiBold.woff2",
    //   weight: "600",
    //   style: "normal",
    // },
    {
      path: "../public/fonts/haffer/HafferSQ-Bold.woff2",
      weight: "700",
      style: "normal",
    },
    // {
    //   path: "../public/fonts/haffer/HafferSQ-BoldItalic.woff2",
    //   weight: "700",
    //   style: "italic",
    // },
    // {
    //   path: "../public/fonts/haffer/HafferSQ-Light.woff2",
    //   weight: "300",
    //   style: "normal",
    // },
  ],
});

const Poppins = localFont({
  display: "swap",
  src: [
    {
      path: "../public/fonts/poppins/Poppins-Regular.ttf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../public/fonts/poppins/Poppins-SemiBold.ttf",
      weight: "600",
      style: "normal",
    },
    {
      path: "../public/fonts/poppins/Poppins-Bold.ttf",
      weight: "700",
      style: "normal",
    },
    {
      path: "../public/fonts/poppins/Poppins-BoldItalic.ttf",
      weight: "700",
      style: "italic",
    },
    {
      path: "../public/fonts/poppins/Poppins-Light.ttf",
      weight: "300",
      style: "normal",
    },
  ],
});

const Walsheim = localFont({
  display: "swap",
  src: [
    {
      path: "../public/fonts/gt_walsheim/GT-Walsheim-Regular.ttf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../public/fonts/gt_walsheim/GT-Walsheim-Bold.ttf",
      weight: "700",
      style: "normal",
    },
  ],
});

const TWKEverett = localFont({
  display: "swap",
  src: [
    {
      path: "../public/fonts/twk_everett/TWKEverett-Regular.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "../public/fonts/twk_everett/TWKEverett-Medium.woff2",
      weight: "600",
      style: "normal",
    },
    {
      path: "../public/fonts/twk_everett/TWKEverett-Bold.woff2",
      weight: "700",
      style: "normal",
    },
  ],
});

const LinkBehaviour = forwardRef(function LinkBehaviour(props, ref) {
  return <Link ref={ref} {...props} />;
});

// commented colors are defined but currently not in use
let theme = createTheme({
  palette: {
    // docfleck colors
    blue: {
      main: "#0CAAED",
      light: "#00BCF4",
      lighter: "#3BCBF5",
      lightest: "#8AD9F5",
    },
    sand: {
      main: "#D6BDAC",
      light: "#E2CABC",
      lighter: "#E9D6CC",
      lightest: "#F5EBE5",
    },
    softGreen: {
      main: "#97C4B0",
      light: "#A2D1BF",
      lighter: "#B9DDCF",
      lightest: "#DDEDE7",
    },
    corall: {
      main: "#EE6978",
      light: "#FF7E8F",
      lighter: "#FF9CA9",
      lightest: "#FCBAC2",
    },
    //baskast colors:
    limeGreen: {
      main: "#CCF653",
      light: "#D6F875",
    },
    darkRed: {
      main: "#2D2C2C",
    },
    darkGreige: {
      dark: "#B6B6A5",
      main: "#EBEBE3",
      light: "#F7F7F4",
    },

    // default colors
    yellow: {
      lighter: "#F8E1A0",
      light: "#F6D579",
      main: "#F3C84F",
      // dark: "#EFB61A",
    },
    darkGreen: {
      lighter: "#1E5C4C",
      // light: "#194D3F",
      main: "#143E33",
      dark: "#0D2821",
    },
    orange: {
      // lighter: "#FFC7A7",
      // light: "#FFB991",
      main: "#FF9153",
      dark: "#FF711F",
    },
    green: {
      lighter: "#8EC3AA",
      light: "#81BCA1",
      main: "#63AD8B",
      dark: "#4D9373",
    },
    softBlack: {
      light: "#D1D1D1",
      main: "#4A4A4A",
      dark: "#1D1D1D",
    },
    pureWhite: {
      main: "#FFFFFF",
    },
    cream: {
      // light: "#FFFDFB",
      main: "#FFFCF5",
      dark: "#FFF9EC",
    },
    primary: {
      main: "#F3C84F",
    },
    secondary: {
      main: "#143E33",
    },
  },
});

const colors = theme.palette;

const defaultTenantTheme = createTheme(theme, {
  palette: {
    primary: {
      main: colors.yellow.main,
    },
    secondary: {
      main: colors.darkGreen.main,
    },
    action: {
      active: colors.green.dark,
    },
  },
  typography: {
    fontFamily: [Poppins.style.fontFamily],
    heroMain: {
      fontSize: 40,
      lineHeight: 1.2,
      fontWeight: "700",
      fontFamily: [Walsheim.style.fontFamily, Poppins.style.fontFamily].join(
        ","
      ),
      textShadow: "0px 2px 2px rgba(29, 29, 29, 0.25)",
      color: colors.pureWhite.main,
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        fontSize: 56,
        lineHeight: 1.15,
      },
    },
    heroSubtitle: {
      fontSize: 16,
      lineHeight: 1.375,
      fontWeight: 400,
      fontFamily: Poppins.style.fontFamily,
      color: colors.pureWhite.main,
      textShadow: "0px 2px 2px rgba(29, 29, 29, 0.25)",
      [theme.breakpoints.up("sm")]: {
        fontSize: 20,
        lineHeight: 1.4,
      },
    },
    h1: {
      fontSize: 36,
      lineHeight: 1.2,
      fontWeight: "700",
      fontFamily: [Walsheim.style.fontFamily, Poppins.style.fontFamily].join(
        ","
      ),
      color: colors.darkGreen.dark,
      [theme.breakpoints.up("sm")]: {
        fontSize: 48,
      },
    },
    h2: {
      fontSize: 32,
      lineHeight: 1.2,
      fontWeight: "700",
      fontFamily: [Walsheim.style.fontFamily, Poppins.style.fontFamily].join(
        ","
      ),
      color: colors.darkGreen.dark,
      [theme.breakpoints.up("sm")]: {
        fontSize: 38,
      },
    },
    h3: {
      fontSize: 24,
      lineHeight: 1.2,
      fontWeight: 700,
      fontFamily: [Walsheim.style.fontFamily, Poppins.style.fontFamily].join(
        ","
      ),
      color: colors.darkGreen.dark,
    },
    h4: {
      fontSize: 20,
      lineHeight: 1.2,
      fontWeight: 700,
      fontFamily: [Walsheim.style.fontFamily, Poppins.style.fontFamily].join(
        ","
      ),
      color: colors.darkGreen.dark,
    },
    h5: {
      fontSize: 16,
      lineHeight: 1.2,
      fontWeight: 700,
      fontFamily: [Poppins.style.fontFamily].join(","),
      color: colors.darkGreen.dark,
    },
    // Body large
    subtitle1: {
      fontSize: 20,
      lineHeight: 1.4,
      letterSpacing: "-0.01em",
      fontWeight: 400,
      fontFamily: Poppins.style.fontFamily,
      color: colors.softBlack.main,
    },
    // Body medium
    body1: {
      fontSize: 16,
      lineHeight: 1.4,
      letterSpacing: "-0.01em",
      fontWeight: 400,
      fontFamily: Poppins.style.fontFamily,
      color: colors.softBlack.main,
    },
    // Body small
    body2: {
      fontSize: 14,
      lineHeight: 1.4,
      letterSpacing: "-0.01em",
      fontWeight: 400,
      fontFamily: Poppins.style.fontFamily,
      color: colors.softBlack.main,
    },
    paragraph: {
      fontSize: 14,
      lineHeight: 1.4,
      letterSpacing: "-0.01em",
      fontWeight: 400,
      fontFamily: [Poppins.style.fontFamily],
      color: colors.softBlack.main,
    },
    // Body xs
    caption: {
      fontSize: 12,
      lineHeight: 1.4,
      letterSpacing: "-0.01em",
      fontWeight: 400,
      fontFamily: [Poppins.style.fontFamily],
      color: colors.softBlack.main,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 64,
      [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
        minHeight: 80,
      },
      [theme.breakpoints.up("md")]: {
        minHeight: 80,
      },
    },
  },
  components: {
    NavHighlight: {
      backgroundColor: colors.darkGreen.main,
    },
    Card: {
      borderRadius: theme.spacing(4),
    },
    Banner: {
      backgroundColor: colors.yellow.lighter,
    },
    Footer: {
      backgroundColor: colors.darkGreen.main,
      color: colors.cream.dark,
    },
    Navigation: {
      colorFaded: colors.pureWhite.main,
      color: colors.darkGreen.main,
      colorListItem: colors.softBlack.dark,
      backgroundColor: colors.pureWhite.main,
      backgroundColorSelected: colors.cream.dark,
    },
    HeroTeaserHomeButton: {
      backgroundColor: null,
      hoverColor: null,
    },
    HeroTeaserHomeContentContainer: {
      maxWidth: theme.breakpoints.values.sm,
      width: "100%",
      backgroundColor: "transparent",
      padding: "0",
    },

    MuiTypography: {
      defaultProps: {
        variantMapping: {
          heroMain: "h1",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehaviour,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehaviour,
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: 0,
          [theme.breakpoints.up("sm")]: {
            padding: 0,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: Poppins.style.fontFamily,
          borderRadius: "100px",
          boxShadow: "none",
          padding: "10px 24px",
          fontSize: 16,
          lineHeight: "22px",
          fontWeight: "600",
          textTransform: "none",
          letterSpacing: "-0.01em",
        },
        containedPrimary: {
          color: colors.darkGreen.dark,
          "&:hover": {
            backgroundColor: colors.primary.main,
            boxShadow:
              "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);",
          },
          "&:disabled": {
            color: colors.softBlack.main,
            backgroundColor: colors.softBlack.dark,
          },
        },
        containedSecondary: {
          color: colors.darkGreen.main,
          backgroundColor: "transparent",
          border: `2px solid ${colors.secondary.main}`,
          "&:hover": {
            // HexSuffix: 33 means "20%" of color
            background: `${colors.green.lighter}33`,
            boxShadow: "none",
          },
          "&:disabled": {
            color: colors.softBlack.main,
            backgroundColor: "transparent",
            borderColor: colors.softBlack.main,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: "0.25rem",
          paddingBottom: 0,
          paddingLeft: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover, &:active, &:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: theme.spacing(4),
          boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          boxShadow: "none",
          marginTop: 0,
          marginBottom: 0,
          "&:before": {
            backgroundColor: colors.darkGreen.dark,
            opacity: "1 !important",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {},
        content: {
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      },
    },
    ButtonBase: {
      secondary: {
        light: {
          color: colors.softBlack.dark,
          borderColor: colors.softBlack.dark,
        },
        dark: {
          color: colors.pureWhite.main,
          borderColor: colors.pureWhite.main,
        },
      },
    },
    ListItemIcon: {
      regular: {
        light: {
          backgroundColor: colors.softBlack.dark,
        },
        dark: {
          backgroundColor: colors.pureWhite.main,
        },
      },
      hero: {
        light: {
          backgroundColor: colors.green.lighter,
        },
        dark: {
          backgroundColor: colors.green.lighter,
        },
      },
    },
    ErrorPageIcon: {
      color: colors.orange.dark,
    },
    Image: {
      borderRadius: theme.spacing(4),
    },
    Video: {
      borderRadius: theme.spacing(2.5),
    },
    ContentSection: {
      borderRadius: theme.spacing(3),
    },
    Arrow: {
      color: colors.pureWhite.main,
      backgroundColor: colors.softBlack.main,
    },
  },
});

export const docfleckTheme = createTheme(defaultTenantTheme, {
  palette: {
    primary: {
      main: colors.blue.main,
    },
    secondary: {
      main: colors.softBlack.main,
    },
    action: {
      active: colors.blue.main,
    },
  },
  typography: {
    fontFamily: [HafferSQ.style.fontFamily],
    heroMain: {
      fontFamily: [HafferSQ.style.fontFamily].join(","),
    },
    heroSubtitle: {
      fontFamily: [HafferSQ.style.fontFamily].join(","),
    },
    h1: {
      fontFamily: [HafferSQ.style.fontFamily].join(","),
      color: colors.softBlack.dark,
    },
    h2: {
      fontFamily: [HafferSQ.style.fontFamily].join(","),
      color: colors.softBlack.dark,
    },
    h3: {
      fontFamily: [HafferSQ.style.fontFamily].join(","),
      color: colors.softBlack.dark,
    },
    h4: {
      fontFamily: [HafferSQ.style.fontFamily].join(","),
      color: colors.softBlack.dark,
    },
    h5: {
      fontFamily: [HafferSQ.style.fontFamily].join(","),
      color: colors.softBlack.dark,
    },
    subtitle1: {
      fontFamily: [HafferSQ.style.fontFamily].join(","),
    },
    body1: {
      fontFamily: [HafferSQ.style.fontFamily].join(","),
    },
    body2: {
      fontFamily: [HafferSQ.style.fontFamily].join(","),
    },
    h5: {
      fontFamily: [HafferSQ.style.fontFamily].join(","),
    },
    paragraph: {
      fontFamily: [HafferSQ.style.fontFamily].join(","),
    },
    caption: {
      fontFamily: [HafferSQ.style.fontFamily].join(","),
    },
  },
  components: {
    NavHighlight: {
      backgroundColor: colors.corall.light,
    },
    Banner: {
      backgroundColor: colors.blue.lightest,
    },
    Footer: {
      backgroundColor: colors.blue.main,
      color: colors.cream.dark,
    },
    Navigation: {
      colorFaded: colors.pureWhite.main,
      color: colors.softBlack.dark,
      colorListItem: colors.softBlack.dark,
      backgroundColor: colors.pureWhite.main,
      backgroundColorSelected: colors.blue.light,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: HafferSQ.style.fontFamily,
        },
        containedPrimary: {
          color: colors.pureWhite.main,
          "&:hover": {
            backgroundColor: colors.blue.light,
            boxShadow: "none",
          },
          "&:disabled": {
            backgroundColor: colors.softBlack.light,
            boxShadow: "none",
          },
        },
        containedSecondary: {
          color: colors.blue.main,
          borderColor: colors.blue.main,
          backgroundColor: "transparent",
          border: `2px solid ${colors.blue.main}`,
          "&:hover": {
            color: colors.pureWhite.main,
            background: colors.blue.main,
            boxShadow: "none",
          },
          "&:disabled": {
            color: colors.softBlack.light,
            backgroundColor: "transparent",
            borderColor: colors.softBlack.light,
            boxShadow: "none",
          },
        },
      },
    },
    ButtonBase: {
      primary: {
        light: {
          color: colors.pureWhite.main,
          borderColor: colors.blue.main,
        },
        dark: {
          color: colors.pureWhite.main,
          borderColor: colors.blue.main,
        },
      },
      secondary: {
        light: {
          color: colors.blue.main,
          borderColor: colors.blue.main,
        },
        dark: {
          color: colors.pureWhite.main,
          borderColor: colors.pureWhite.main,
        },
      },
    },
    ListItemIcon: {
      regular: {
        light: {
          backgroundColor: colors.blue.main,
        },
        dark: {
          backgroundColor: colors.pureWhite.main,
        },
      },
      hero: {
        light: {
          backgroundColor: colors.blue.main,
        },
        dark: {
          backgroundColor: colors.blue.main,
        },
      },
    },
    ErrorPageIcon: {
      color: colors.corall.light,
    },
    Image: {
      borderRadius: theme.spacing(4),
    },
  },
});
export const baskastTheme = createTheme(defaultTenantTheme, {
  palette: {
    primary: {
      main: colors.limeGreen.main,
    },
    secondary: {
      main: colors.darkGreige.dark,
    },
    action: {
      active: colors.limeGreen.main,
    },
  },
  typography: {
    fontFamily: [TWKEverett.style.fontFamily],
    heroMain: {
      fontFamily: [TWKEverett.style.fontFamily].join(","),
      fontWeight: 500,
      lineHeight: 1.5,
      fontSize: 29,
      color: colors.darkRed.main,

      textTransform: "uppercase",
      textShadow: "none",

      [theme.breakpoints.up("sm")]: {},
    },
    heroSubtitle: {
      fontFamily: [TWKEverett.style.fontFamily].join(","),
      fontWeight: 500,

      color: colors.darkRed.main,
      textShadow: "none",

      [theme.breakpoints.up("sm")]: {},
    },

    h1: {
      fontFamily: [TWKEverett.style.fontFamily].join(","),
      fontWeight: 500,
      [theme.breakpoints.up("sm")]: {},
    },
    h2: {
      fontFamily: [TWKEverett.style.fontFamily].join(","),
      fontWeight: 500,
      [theme.breakpoints.up("sm")]: {},
    },
    h3: {
      fontFamily: [TWKEverett.style.fontFamily].join(","),
      fontWeight: 500,
    },
    h4: {
      fontFamily: [TWKEverett.style.fontFamily].join(","),
      fontWeight: 400,
    },
    h5: {
      fontFamily: [TWKEverett.style.fontFamily].join(","),
      fontWeight: 700,
    },
    subtitle1: {
      fontFamily: [TWKEverett.style.fontFamily].join(","),
      fontWeight: 400,
    },
    body1: {
      fontFamily: [TWKEverett.style.fontFamily].join(","),
      fontWeight: 400,
      [theme.breakpoints.up("sm")]: {},
    },
    body2: {
      fontFamily: [TWKEverett.style.fontFamily].join(","),
      fontWeight: 400,
    },
    paragraph: {
      fontFamily: [TWKEverett.style.fontFamily].join(","),
      fontWeight: 400,
    },
    caption: {
      fontFamily: [TWKEverett.style.fontFamily].join(","),
      fontWeight: 400,
    },
  },
  components: {
    NavHighlight: {
      backgroundColor: colors.darkRed.main,
    },
    Card: {
      borderRadius: 0,
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "0",
          boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
        },
      },
    },
    Navigation: {
      colorFaded: colors.darkRed.main,
      color: colors.darkRed.main,
      colorListItem: colors.darkRed.main,
      backgroundColor: colors.pureWhite.main,
      backgroundColorSelected: colors.limeGreen.main,
    },
    Banner: {
      backgroundColor: colors.limeGreen.main,
    },
    Footer: {
      backgroundColor: colors.darkRed.main,
      color: colors.pureWhite.main,
    },
    HeroTeaserHomeButton: {
      backgroundColor: colors.softBlack.dark,
      hoverColor: colors.darkRed.main,
    },
    HeroTeaserHomeContentContainer: {
      maxWidth: theme.breakpoints.values.sm,
      //lime green with .8 opacity
      backgroundColor: "#ccf653cc",
      padding: theme.spacing(4, 4, 4, 4),
    },

    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: TWKEverett.style.fontFamily,
        },
        containedPrimary: {
          color: colors.darkRed.main,
          backgroundColor: colors.limeGreen.main,
          fontStyle: "normal",
          fontWeight: 500,
          letterSpacing: "1.95px",
          textTransform: "uppercase",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: colors.limeGreen.light,
            boxShadow: "none",
          },
          "&:disabled": {
            backgroundColor: colors.limeGreen.light,
            boxShadow: "none",
            color: colors.darkGreige.dark,
          },
        },
        containedSecondary: {
          color: colors.darkRed.main,
          borderColor: "transparent",
          backgroundColor: colors.pureWhite.main,
          border: `none`,
          fontStyle: "normal",
          fontWeight: 500,
          letterSpacing: "1.95px",
          textTransform: "uppercase",
          boxShadow: "none",
          "&:hover": {
            color: colors.darkRed.main,
            background: colors.darkGreige.light,
            boxShadow: "none",
          },
          "&:disabled": {
            backgroundColor: colors.limeGreen.main,
            color: colors.darkGreige.dark,
            borderColor: colors.softBlack.light,
            boxShadow: "none",
          },
        },
      },
    },
    ButtonBase: {
      primary: {
        light: {
          backgroundColor: colors.pureWhite.main,
          color: colors.darkRed.main,
          borderColor: colors.darkRed.main,
        },
        dark: {
          backgroundColor: colors.limeGreen.main,
          color: colors.darkRed.main,
          borderColor: colors.darkRed.main,
        },
      },
      secondary: {
        light: {
          color: colors.darkRed.main,
          borderColor: colors.limeGreen.main,
        },
        dark: {
          color: colors.darkRed.main,
          borderColor: colors.darkRed.main,
        },
      },
    },
    ListItemIcon: {
      regular: {
        light: {
          backgroundColor: colors.darkRed.main,
        },
        dark: {
          backgroundColor: colors.limeGreen.main,
        },
      },
      hero: {
        light: {
          backgroundColor: colors.darkRed.main,
        },
        dark: {
          backgroundColor: colors.limeGreen.main,
        },
      },
    },
    ErrorPageIcon: {
      color: colors.orange.light,
    },
    Image: {
      borderRadius: 0,
    },
    Video: {
      borderRadius: 0,
    },
    ContentSection: {
      borderRadius: 0,
    },
    Arrow: {
      color: colors.pureWhite.main,
      backgroundColor: colors.darkGreige.dark,
    },
  },
});
export default defaultTenantTheme;

export const getTenantTheme = (tenantName) => {
  return tenantName === "docfleck"
    ? docfleckTheme
    : tenantName === "baskast"
    ? baskastTheme
    : defaultTenantTheme;
};
