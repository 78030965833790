import { deleteLSCart, saveLSCart } from "../localstorage/cart";
import { Cart, CartLineItemWithProduct } from "./cart";

type UpdateType = "SHOPIFY" | "LOCAL";

interface SetItemAction {
  type: "SET_ITEM";
  payload: { setLoad: boolean; item: CartLineItemWithProduct };
  updateType: UpdateType;
}

interface DeleteItemAction {
  type: "DELETE_ITEM";
  payload: CartLineItemWithProduct;
  updateType: UpdateType;
}

interface SetItemCostAction {
  type: "SET_ITEM_COST";
  payload: { [variantId: string]: CartLineItemWithProduct };
  updateType: UpdateType;
}

interface LoadItemsAction {
  type: "LOAD_ITEMS";
  payload: Cart;
  updateType: UpdateType;
}

type CartActionType =
  | SetItemAction
  | DeleteItemAction
  | SetItemCostAction
  | LoadItemsAction;

export const CartReducer = (state: Cart, action: CartActionType): Cart => {
  switch (action.type) {
    case "LOAD_ITEMS":
      return loadItems(state, action.payload, action.updateType);
    case "SET_ITEM_COST":
      return setItemCost(state, action.payload, action.updateType);
    case "SET_ITEM":
      return setItem(state, action.payload, action.updateType);
    case "DELETE_ITEM":
      return deleteItem(state, action.payload, action.updateType);
    default:
      return state;
  }
};

const loadItems = (state: Cart, cart: Cart, updateType: UpdateType): Cart => {
  cart.updateStatus = updateType;
  cart.isUpdatingPrices = false;
  // console.log("loading items", cart);
  return cart;
};

const setItemCost = (
  state: Cart,
  cartItems: { [variantId: string]: CartLineItemWithProduct },
  updateType: UpdateType
): Cart => {
  // console.log("Updating item cost");
  let newItems = { ...cartItems };

  Object.keys(cartItems).forEach((cartItemId) => {
    const cartItem = cartItems[cartItemId];
    if (cartItem.amount > 0) {
      const variantId = cartItem.product.variantId;
      const itemCost = cartItem.cost;
      const id = cartItem.id;
      newItems[variantId] = {
        ...state.items[variantId],
        cost: itemCost,
        id,
      };
    }
  });
  let newState = {
    ...state,
    items: newItems,
  };
  newState.updateStatus = updateType;
  newState.isUpdatingPrices = false;
  saveLSCart(newState);
  if (
    Object.keys(newState.items).reduce(
      (prev, curr) => prev + newState.items[curr].amount,
      0
    ) <= 0
  ) {
    deleteLSCart();
    newState.items = {};
    newState.isUpdatingPrices = false;
    newState.cartId = "";
  }
  return newState;
};

const setItem = (
  state: Cart,
  payload: { setLoad: boolean; item: CartLineItemWithProduct },
  updateType: UpdateType
): Cart => {
  // console.log("Updating item amount");
  const cartItem = payload.item;
  let itemId = cartItem.product.variantId;
  let newState = {
    ...state,
    items: {
      ...state.items,
      [itemId]: {
        ...cartItem,

        cost: { ...cartItem.cost },
        discountAllocations: cartItem.discountAllocations,
      },
    },
  };
  newState.updateStatus = updateType;

  newState.isUpdatingPrices = payload.setLoad;

  saveLSCart(newState);
  return newState;
};

const deleteItem = (
  state: Cart,
  cartItem: CartLineItemWithProduct,
  updateType: UpdateType
): Cart => {
  const itemId = cartItem.product.variantId;
  const newState = {
    ...state,
    items: {
      ...state.items,
    },
  };
  delete newState.items[itemId];

  newState.updateStatus = updateType;
  saveLSCart(newState);
  return newState;
};
