import { Theme } from "@mui/material";

/**
 * Applies the theme specific styles to the documents stylesheet.
 * @param theme
 */
function applyThemeToStylesheet(theme: Theme) {
  if (typeof document !== "undefined") {
    let overrides = theme.components?.MuiButton?.styleOverrides as {
      containedPrimary: {
        color: string;
        "&:hover": { boxShadow: string; backgroundColor: string };
      };
    };

    document.documentElement.style.setProperty(
      "--buttonColor",
      theme.palette.primary.main
    );
    document.documentElement.style.setProperty(
      "--fontColor",
      theme.palette.text.primary
    );
    document.documentElement.style.setProperty(
      "--buttonFontColor",
      overrides.containedPrimary.color
    );
    document.documentElement.style.setProperty(
      "--buttonColorHover",
      overrides.containedPrimary["&:hover"].backgroundColor
    );
    document.documentElement.style.setProperty(
      "--buttonBoxShadowHover",
      overrides.containedPrimary["&:hover"].boxShadow
    );
    document.documentElement.style.setProperty(
      "--fontHeading",
      theme.typography.h1.fontFamily as string
    );
    document.documentElement.style.setProperty(
      "--fontNormal",
      theme.typography.body1.fontFamily as string
    );
    document.documentElement.style.setProperty(
      "--linkColor",
      theme.palette.action.active
    );
  }
}

export default applyThemeToStylesheet;
